.topbar {
    width: 100%;
    height: 80px;
  
    position: sticky;
    top: 0;
    z-index: 200000;
    font-family:"FuturaPT-Bold"
  }
  
  .topbarWrapper {
    height: 100%;
    padding: 0px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: white;
  }
  
  .logo {
    font-weight: bold;
    font-size: 30px;
    color: darkblue;
    cursor: pointer;
    text-decoration: none;
  }
  
  .topRight{
      display: flex;
      align-items: center;
      justify-content: flex-end;
  }
  
  .topbarIconContainer {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
  }
  
  .topIconBadge{
      width: 15px;
      height: 15px;
      position: absolute;
      top: -5px;
      right: 0px;
      background-color: red;
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
  }
  
  .topAvatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
  }
  
  @media (max-width:480px)  { 
    .logo {
      font-size: 20px
    }
  }